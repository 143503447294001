
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinDocument from '@/mixins/mixinDocument';
import CommonProgress from "@/components/common/Progress.vue";
import DocumentSpwaiting from "@/components/document/Spwaiting.vue";
import DocumentWaiting from "@/components/document/Waiting.vue";

@Component({
  components: {
    CommonProgress,
    DocumentSpwaiting,
    DocumentWaiting,
  }
})
export default class Document extends Mixins(mixinDocument) {

}
